<template>
  <div id="order-view-action-link-to-invoice">
    <b-button
      id="order-view-action-link-to-invoice-btn"
      v-ripple.400
      variant="outline-secondary"
      class="mb-75"
      block
      :to="{ name: invoiceRouteName, params: { id: quoteSelected.invoice.id } }"
    >
      <feather-icon icon="FileTextIcon" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ $t('action.invoice') }} </span>
    </b-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'OrderViewActionLinkToInvoice',

  computed: {
    ...mapState('quote', ['quoteSelected']),

    invoiceRouteName() {
      if (this.$route.name === 'orderAccountingShow') {
        return 'invoiceAccountingShow'
      }

      return 'invoiceShow'
    },
  },
}
</script>
